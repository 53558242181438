<template>
  <div class="zcxy">
    <div class="clearfix table-tools">
      <div class="buttons">
        <a-form layout='inline'>
          <a-form-item v-if="canSave">
            <a-button type="primary" icon="plus" @click="toAddEditModal()">学员入读</a-button>
          </a-form-item>
          <a-form-item v-if="canMerge">
            <a-button icon="fork" @click="showMergeModal()">学员合并</a-button>
          </a-form-item>
          <a-form-item v-if="canExcelexport">
            <a-button icon="export" @click="toExport" :loading="exportLoading">导出</a-button>
          </a-form-item>
        </a-form>
      </div>
      <div class="search">
        <a-form layout='inline' @submit="searchList">
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>学员名称/手机号</span>
              </template>
              <a-input v-model.trim="searchParams.search.fuzzy" placeholder="学员名称/手机号" style="width: 160px" allowClear/>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
              <a-radio-group :value="searchParams.search.is_demo">
                <a-radio-button @click="handleTypeButChange('0','is_demo')" value="0">
                    常规课时
                </a-radio-button>
                <a-radio-button @click="handleTypeButChange('1','is_demo')" value="1">
                    体验课时
                </a-radio-button>
              </a-radio-group>
            </a-form-item>
          <a-form-item>
            <a-button type="primary" html-type="submit" :loading="loading" @click="searchList">搜索</a-button>
          </a-form-item>
          <a-form-item>
            <a-button @click="searchVisible = !searchVisible">更多<a-icon v-if="!searchVisible" type="down" /><a-icon v-if="searchVisible" type="up" /></a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div v-show="searchVisible" class="more-search zcxy-seach" style="width:100%">
      <a-form layout='inline'>

        <a-form-item label="所在校区" class="block-line" >
          <a-select v-model="searchParams.search.studio_id" mode="multiple" :maxTagCount='1' :maxTagTextLength='5' showSearch allowClear placeholder="请选择" :filterOption="filterOption">
            <a-select-option v-for="(item, index) of studios" :key="index" :value="item.studio_id">{{ item.filter_name }}</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="学籍号" class="block-line" >
          <a-input v-model.trim="searchParams.search.student_number" placeholder="请输入" allowClear/>
        </a-form-item>

        <a-form-item label="学员性别" class="block-line" >
          <a-select v-model="searchParams.search.student_gender" allowClear placeholder="请选择">
            <a-select-option v-for="(item, index) of sexs" :key="index" :value="item.value">{{ item.label }}</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="年级" class="block-line" >
          <a-select v-model="searchParams.search.school_year_grade" :maxTagCount='1' allowClear placeholder="请选择" mode="multiple">
            <a-select-option v-for="(d, index) of grades" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
          </a-select>
        </a-form-item>
        
        <a-form-item label="课程顾问" class="block-line" >
          <a-select v-model="searchParams.search.cc_id" showSearch allowClear :maxTagCount='1' placeholder="请选择" :filterOption="filterOption" mode="multiple">
            <a-select-option v-for="(item, index) of consultants" :key="index" :value="item.course_consultant_id">{{ item.filter_name }}</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="招生来源" class="block-line" >
          <a-tree-select
              v-model="searchParams.search.come_from"
              :tree-data="sourceChannels"
              tree-checkable
              allowClear
              treeNodeFilterProp="title"
              :maxTagCount='1'
              :dropdownStyle="{zIndex:4200,maxHeight:'400px'}"
              :show-checked-strategy="SHOW_ALL"
              placeholder="请选择招生来源"
          />  
        </a-form-item>
        <a-form-item label="学员状态" class="block-line" >
          <a-select v-model="searchParams.search.student_class_status" :maxTagCount='1' allowClear placeholder="请选择" mode="multiple">
            <a-select-option v-for="(item, index) of studentStatus" :key="index" :value="item.value">{{ item.label }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="微信状态" class="block-line" >
          <a-select v-model="searchParams.search.user_wechat" allowClear placeholder="请选择">
            <a-select-option v-for="(item, index) of wechatBindStatus" :key="index" :value="item.value">{{ item.label }}</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item class="block-line" label="剩余课时">
              <a-input-group compact>
                  <a-select style="width:100px" v-model="customer_optionValue" @change="onChangeCostWarn">
                      <a-select-option v-for="(item,index) in customer_optionList" :key="index" :value="item.value">{{item.label}}</a-select-option>
                  </a-select>
                  <a-input-number style="width:140px" v-model="customer_number" :min="0" @change="onChangeCostWarn" />
              </a-input-group>
        </a-form-item>

        <!-- <a-form-item label="创建时间" class="block-line">
            <a-range-picker
                :ranges="rangesData"
                :value='rangesValue'
                :disabled-date="disabledDate" :allowClear="false"
                :default-value="[moment(searchParams.search.start_date,'YYYY-MM-DD'),moment(searchParams.search.end_date,'YYYY-MM-DD')]"
                style="width: 240px"
                @change="(date, dateString)=>onChange(date, dateString,1)" />
        </a-form-item> -->

      </a-form>
      <div class="more-search__buttons">
        <a-button type="dashed" @click="closeSearch">取消</a-button>
        <a-button @click="reset">重置</a-button>
        <a-button type="primary" @click="searchList">搜索</a-button>
      </div>
    </div>
    
    <div class="table">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-table size="middle" :pagination="false" :bordered='false' rowKey="student_id"  @change="handleChange"
        :columns="columns" :dataSource="list" :scroll="{ x: 1200 }">
        <template slot="index" slot-scope="text, record , index">
          <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
        </template>

        <template slot="studentName" slot-scope="text, record">
          <LUser @eventSon="toUpdataL" :data="record" :isRefresh="1" :is_student_wechat_bind="1"/>
        </template>

        <template slot="student_face" slot-scope="text, record">
            <!-- <a v-if="!record.student_face" >未采集</a> -->
            <a href="javascript:;" v-if="!record.student_face" style="color:#FCC955;" @click="showFaceModal(record)">未采集</a>
            <a href="javascript:;" v-if="record.student_face" @click="showFaceModal(record)">采集完成</a>
        </template>

        <template slot="null_txt" slot-scope="text, record">
            <span v-if="text">{{text}}</span>
            <span v-else> - </span>
        </template>

        <template slot="student_wechat_bind" slot-scope="text, record">
          <svg style="width: 12px;height: 12px;cursor: pointer;" aria-hidden="true" @click="showQrcodeModal(record)">
            <use v-show="record.student_wechat_bind == 1" xlink:href="#icon-weixin"></use>
            <use v-show="record.student_wechat_bind == 0" xlink:href="#icon-weixin1"></use>
          </svg>
        </template>


        <template slot="cellphone" slot-scope="text,record">
            <div @click.stop v-clipboard:copy="text" v-clipboard:success="onCopy" v-clipboard:error="onError">
              {{record.cellphone}}
              <a-icon type="copy" :style="{ fontSize: '12px'}" />
          </div>
        </template>

        <template slot="student_course_status" slot-scope="text,record">
            <a-tag color="#AAA492" v-if="text=='注册用户'">
                注册用户
            </a-tag>
            <a-tag color="#AAA492" v-if="text=='潜在学员'">
                潜在学员
            </a-tag>
            <a-tag color="#00cca2" v-if="text=='在读'">
                在读
            </a-tag>
            <a-tag color="#f50" v-if="text=='待排班'">
                待排班
            </a-tag>  
            <a-tag color="#97afd0 " v-if="text=='停课'">
                停课
            </a-tag>    
            <a-tag color="#97afd0 " v-if="text=='历史'">
                历史
            </a-tag>    
        </template>

        <template slot="action" slot-scope="text,record">
          
          <a-dropdown>
            <a class="ant-dropdown-link" @click="e => e.preventDefault()">操作<a-icon type="down" /></a>
            <a-menu slot="overlay">
              <a-menu-item v-if="canUpdate" key="1" @click="showUpdateModal(record)">
                信息编辑
              </a-menu-item>
              <a-menu-item key="2" @click="toeditModal(record)">
                业务办理
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </template>

      </a-table>
    </div>
    <div class="page">
        <a-pagination
          :pageSizeOptions="pageSizeOptions"
          :total="pageParams.totalCount"
          showSizeChanger
          :pageSize="pageParams.perPage"
          v-model="current"
          @change="changePage"
          @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
        >
            <template slot='buildOptionText' slot-scope='props'>
              <span v-if="props.value!=='100'">{{props.value}}条/页</span>
              <span v-if="props.value==='100'">100条/页</span>
            </template>
          </a-pagination>
    </div>
    <faceModal v-if="isFaceModal" :item="modalData"/>
    <editModal v-if="editModalVisible" :tab="1" :item="edModalData"/>
    <updateModal v-if="updateVisible" :item="modalData"/>
    <mergeModal v-if="isShowMergeModal" :item="modalData"/>
    <qrcodeModal v-if="isQrcodeModal" :item="modalData"/>
    <profileModal v-if="profileVisible" :studentId="studentId" />
    <!-- <exportModal v-if="exportLoading" :exportData='exportData' :exportInfo='exportInfo' /> -->
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' } , key: 'index', fixed: 'left'},
  { title: '学员名称', width: '140px', dataIndex: 'student_name', key: 'student_name', fixed: 'left', scopedSlots: { customRender: 'studentName' } },
  { title: '', width: '30px', dataIndex: 'student_wechat_bind', key: 'student_wechat_bind', fixed: 'left', scopedSlots: { customRender: 'student_wechat_bind' } },
  { title: '手机号码', width: '120px', dataIndex: 'cellphone', key: 'cellphone',scopedSlots: { customRender: 'cellphone' }},
  { title: '年龄', width: '90px', dataIndex: 'student_age', key: 'student_age', scopedSlots: { customRender: 'null_txt' }, align:'center', sorter:()=>{}},
  { title: '学籍号', width: '90px', dataIndex: 'student_number', key: 'student_number', scopedSlots: { customRender: 'null_txt' }, align:'center', sellipsis:true, sorter:()=>{}},
  // { title: '人脸采集', width: '80px',scopedSlots: { customRender: 'student_face' }, key: 'student_face',align:'center'},
  { title: '剩余课时', width: '95px', dataIndex: 'student_remain_times', key: '4' ,align:"right",sorter:()=>{}},
  { title: '剩余赠课', width: '95px', dataIndex: 'left_gift_times', key: '7' ,align:"right",sorter:()=>{}},
  { title: '报班数', width: '95px', dataIndex: 'student_class_member', key: '6' ,align:"right", sorter:()=>{}},
  { title: '上课次数', width: '95px', dataIndex: 'student_classes', key: '8' ,align:"right", sorter:()=>{}},
  { title: '请假次数', width: '95px', dataIndex: 'student_cancel_classes', key: '9' ,align:"right", sorter:()=>{}},
  { title: '消费次数', width: '95px', dataIndex: 'student_cost_times', key: '10' ,align:"right", sorter:()=>{}},
  { title: '钱包余额', width: '95px', dataIndex: 'left_wallet_reserve', key: 'left_wallet_reserve' ,align:"right", sorter:()=>{}},
  { title: '消费金额', width: '95px', dataIndex: 'student_costs', key: '11' ,align:"right", sorter:()=>{}},
  { title: '签约校区', width: '95px', dataIndex: 'studio_id', key: 'studio_id' ,ellipsis:true },
  { title: '招生来源', width: '95px', dataIndex: 'student_channel_id', key: 'student_channel_id',ellipsis:true},
  { title: '课程顾问', width: '95px', dataIndex: 'course_consultant_id', key: 'course_consultant_id',ellipsis:true,sorter:()=>{}},
  { title: '报名时间', width: '95px', dataIndex: 'student_in_date', key: 'student_in_date' ,ellipsis:true,sorter:()=>{}},
  { title: '学员状态', width: '95px', dataIndex: 'student_course_status', key: '13',align:"center",scopedSlots: { customRender: 'student_course_status' },sorter:()=>{}},
  { title: '操作', key: 'operation', fixed: 'right', width: 60, scopedSlots: { customRender: 'action' }}
]
import url from '@/utils/URL'
import createFilePath from '@/utils/tools'
import tableMixins from '@/common/mixins/table'
import ranges from "@/common/mixins/ranges"
import editModal from './editModal'
import updateModal from './updateModal'
import mergeModal from './mergeModal'
import qrcodeModal from './qrcodeModal'
import cItems from '@/utils/items'
import moment from 'moment'
import faceModal from './faceModal.vue'
// import exportModal from '@/views/export/index'
import profileModal from '@/views/modal/profile'
import authority from '@/common/mixins/authority'

export default {
  name: 'bjgl',
  mixins: [ tableMixins, authority ,ranges],
  components: {
    editModal,
    faceModal,
    // exportModal,
    updateModal,
    profileModal,
    mergeModal,
    qrcodeModal
  },
  provide() {
    return {
      parent: this
    }
  },
  data() {
    return {
      loading: false,
      exportLoading:false,
      columns,
      studios: [],
      consultants: [],
      channelsId:[],
      sourceChannels: [],
      rangesValue:[],
      studentStatus: cItems.studentStatus,
      wechatBindStatus: cItems.wechatBindStatus,
      sexs: cItems.sexs,
      grades:cItems.grade,
      searchParams:{
        search:{
          is_demo:'',
          start_date:null,
          end_date:null,
          student_class_status:[1,2,3]
        }
      },
      isFaceModal:false,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      updateVisible: false,
      editModalVisible:false,
      profileVisible: false,
      isShowMergeModal:false,
      isQrcodeModal:false,
      studentId: '',
      edModalData:{},
      exportData:{},
      exportInfo:{
          url:'studentsExportAction',
          name:'学员列表导出'
      },
      uploadUrl: url.uploadQiNiu, 
      uploadParams: {},
      authType:['students','students'],

      customer_optionList:[
                    {label:'不限',value:''},
                    {label:'小于',value:'<'},
                    {label:'小于等于',value:'<='},
                    {label:'大于',value:'>'},
                    {label:'大于等于',value:'>='},
                ],
      customer_optionValue:'',
      customer_number:'',
    }
  },
  created() {
    this.getStudioList()
    this.getConsultantList()
    this.getSourceChannelList()
  },

  methods: {
    onCopy(){
        this.$store.dispatch('toolsClickAction', {data: {event_type:'event_student_cellphone_click'}})
        this.$message.success('已复制到剪切板!')
    },
    onError(){
        this.$message.error('操作失败!')
    },
    onChangeCostWarn(){
        if(this.customer_optionValue && this.customer_number){
            this.searchParams.search.times = `${this.customer_optionValue} ${this.customer_number}`
        }else{
            this.searchParams.search.times = undefined
        }
        this.changeBadge()
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    showFaceModal(item){
      this.modalData = item
      this.isFaceModal = true
    },
    async beforeUpload(file) {
      console.log(1)
      if (!file) { return false }
      let res = await this.$store.dispatch('imageTokenAction', {})
      if (res) {
        this.uploadParams.token = res.data.uptoken
        this.uploadParams.key = createFilePath(file.name)
        return true
      }
      return false
    },
    async changeFile(file) {
      const { path_id } = this.currentCategory
      if (file.file && file.file.status === 'done') {
        console.log(file)
        let {size, type} = file.file
        const data = {
          path_id,
          image: [{
            name: file.file.name,
            path: file.file.response.key,
            size,
            type
          }]
        }
        let res = await this.$store.dispatch('materialUploadAction', { data })
        if (res) {
          this.getList()
        }
      }
    },
    async getList() {
      this.loading = true
      let res = await this.$store.dispatch('studentsAction', { data: this.searchParams })
      this.list = res.items
      this.pageParams = res._meta
      this.loading = false
    },

    async getStudioList() {
      let res = await this.$store.dispatch('searchBelongStudioAction', { })
      this.studios = res.data
    },
    async getConsultantList() {
      let res = await this.$store.dispatch('searchConsultantAction', { })
      this.consultants = res.data
    },
    async getSourceChannelList() {
      let res = await this.$store.dispatch('searchSourceChannelCascaderAction', {})
      this.sourceChannels = res.data
      
    },

    toProfile(item) {
      // this.$router.push(`/work/profile/${item.student_id}`)
      this.studentId = item.student_id
      this.showProfileModal()
    },

    showProfileModal() {
      this.profileVisible = true
    },
    hideProfileModal() {
      this.profileVisible = false
    },
    toeditModal(item){
      this.edModalData = {student_id: item.student_id,student_name:item.student_name}
      this.showEditModal()
    },
    toAddEditModal(){
      this.edModalData = {}
      this.showEditModal()
    },
    showEditModal() {
      this.editModalVisible = true
    },
    hideModal() {
      this.editModalVisible = false
    },
    toUpdataL(){
      this.getList()
    },
    closeImg(e) {
      e.stopPropagation()
      this.imageUrl = ''
    },
    showUpdateModal(item) {
      this.modalData = item
      this.updateVisible = true
    },
    hideUpdateModal(type) {
      this.updateVisible = false
      if (type === 1) {
        this.getList()
      }
    },
    showMergeModal(){
      this.isShowMergeModal = true
    },
    hideMergeModal(val){
      if(val){
        this.getList()
      }
      this.isShowMergeModal = false
    },
    showQrcodeModal(item){
      this.modalData = item
      this.isQrcodeModal = true
    },
    handleChange(pagination, filters, sorter){
      if(sorter.order){
          if(sorter.order === "ascend"){
              this.searchParams['sort'] = `${sorter.field}`
          }else{
              this.searchParams['sort'] = `-${sorter.field}`
          }
          }else{
          this.searchParams['sort'] = ''
      }
      this.getList()
    },
    handleTypeButChange(e,name){
      if(this.searchParams.search[name] == e){
        this.searchParams.search[name] = undefined
      }else{
        this.searchParams.search[name] = e
      }
      this.getList()
    },
    hideQrcodeModal(val){
      if(val){
        this.getList()
      }
      this.isQrcodeModal = false
    },
    // async toExport(){
    //     let exportData = {
    //         search:this.searchParams.search,
    //         sort:this.searchParams.sort
    //     }
    //     exportData.search.student_id = this.selectedRowKeys
    //     this.exportData = exportData
    //     this.exportLoading = true
    // },
    // hideExportModal(){
    //     this.exportLoading = false
    // },
    async toExport(){
      this.exportLoading = true
      let searchData = {
        search:this.searchParams.search,
        sort:this.searchParams.sort
      }
      let res = await this.$store.dispatch('studentsExportAction', searchData)
      const blob = new Blob([res], {type: 'application/vnd.ms-excel'})
      if ('download' in document.createElement('a')) { 
          const link = document.createElement('a')
          
          link.download = `在籍学员.${moment().format('YYYY.MM.DD')}.xlsx`
          link.style.display = 'none'
          link.href = URL.createObjectURL(blob)
          document.body.appendChild(link)
          link.click()
          URL.revokeObjectURL(link.href) 
          document.body.removeChild(link)
      } else { //其他浏览器
          navigator.msSaveBlob(blob, fileName)
      }
      this.exportLoading = false
    }
  }
}
</script>
<style lang="scss">
  .zcxy {
    .velmld-overlay{
      z-index: 800 !important;
    }
  }
  .zcxy-popover{
    width: 70%;
    .more-search{
      width: 100%;
    }
  }
  .zcxy-seach{
    border: 1px solid #e8e8e8;
    margin-bottom: 15px;
    padding: 10px 0;
    border-radius: 1px;
  }
</style>
