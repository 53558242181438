<template>
  <a-modal title="选择学员" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="65.5%"
    @ok="handleOk" @cancel="handleCancel">
        <div>
            <div class="clearfix table-tools">
                <div class="search">
                    <a-form layout='inline' @submit="searchList">
                    <a-form-item>
                        <a-tooltip placement="topLeft" >
                        <template slot="title">
                            <span>学员名称/手机号</span>
                        </template>
                        <a-input v-model.trim="searchParams.search.fuzzy" placeholder="学员名称/手机号" style="width: 160px"/>
                        </a-tooltip>
                    </a-form-item>
                    <a-form-item>
                        <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
                    </a-form-item>
                    </a-form>
                </div>
            </div>
            <div class="table">
                <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                <a-table size="small" :pagination="false" :bordered='false' rowKey="student_id"
                :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}" :customRow="clickRow"
                    :columns="columns" :dataSource="list" :scroll="{ x: 700 }" class="mergeStudentModal-table">
                    <template slot="index" slot-scope="text, record , index">
                    <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
                    </template>

                    <template slot="studentName" slot-scope="text, record">
                    <LUser :data="record" :is_student_wechat_bind="1"/>
                    </template>

                    <template slot="student_wechat_bind" slot-scope="text, record">
                    <svg style="width: 12px;height: 12px" aria-hidden="true">
                        <use v-show="record.student_wechat_bind == 1" xlink:href="#icon-weixin"></use>
                        <use v-show="record.student_wechat_bind == 0" xlink:href="#icon-weixin1"></use>
                    </svg>
                    </template>

                    <template slot="student_course_status" slot-scope="text,record">
                        <a-tag color="#00cca2" v-if="text=='在读'">
                            在读
                        </a-tag>
                        <a-tag color="#f50" v-if="text=='待排班'">
                            待排班
                        </a-tag>  
                        <a-tag color="#97afd0 " v-if="text=='停课'">
                            停课
                        </a-tag>    
                    </template>
                </a-table>
            </div>
        </div>
  </a-modal>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' } , key: 'index', fixed: 'left'},
  { title: '学员名称', width: '140px', dataIndex: 'student_name', key: 'student_name', fixed: 'left', scopedSlots: { customRender: 'studentName' } },
  { title: '', width: '30px', dataIndex: 'student_wechat_bind', key: 'student_wechat_bind', fixed: 'left', scopedSlots: { customRender: 'student_wechat_bind' } },
  { title: '手机号码', width: '150px', dataIndex: 'cellphone', key: 'cellphone'},
  { title: '剩余课时', dataIndex: 'left_times', key: '4' ,align:"center"},
  { title: '剩余赠课', dataIndex: 'left_gift_times', key: '7' ,align:"center"},
  { title: '报班数', dataIndex: 'student_class_member', key: '6' ,align:"center"},
  { title: '上课次数', dataIndex: 'student_classes', key: '8' ,align:"center"},
//   { title: '请假次数', dataIndex: 'student_cancel_classes', key: '9' ,align:"right"},
//   { title: '消费次数', dataIndex: 'student_cost_times', key: '10' ,align:"right"},
//   { title: '消费金额', dataIndex: 'student_costs', key: '11' ,align:"right"},
//   { title: '签约校区', dataIndex: 'studio_id', key: 'studio_id' ,ellipsis:true},
//   { title: '招生来源', dataIndex: 'student_channel_id', key: 'student_channel_id' ,ellipsis:true},
//   { title: '学员状态', dataIndex: 'student_course_status', key: '13',align:"center",scopedSlots: { customRender: 'student_course_status' }},
]
    export default {
        name:'mergeStudentModal',
        inject: ['parent'],
        data() {
            return {
                columns,
                visible: false,
                loading: false,
                confirmLoading: false,
                list:[],
                searchParams: {
                    "page": 1,
                    "per-page": 20,
                    "search": {
                        student_class_status:[
                            -1,0,1,2,3,
                        ]
                    },
                    "sort": ''
                },
                selectedRowKeys:[],
                selectedRecord:{}
            }
        },
        props:{
            isTab: Number
        },
        created () {
            this.visible = true
        },
        methods: {
            async getList() {
                this.loading = true
                let res = await this.$store.dispatch('studentsAction', { data: this.searchParams })
                this.list = res.items
                this.pageParams = res._meta
                this.loading = false
            },
            handleOk() {
                this.parent.hideMergeStudentModal(this.selectedRecord[0],this.isTab)
            },
            handleCancel() {
                this.parent.hideMergeStudentModal()
            },
            onSelectChange(selectedRowKeys,record) {
                this.selectedRowKeys = [selectedRowKeys[selectedRowKeys.length-1]]
                this.selectedRecord = [record[record.length-1]]
            },
            clickRow(record, index) {
                return {
                    on: {
                        click: () => {
                            let index = this.selectedRowKeys.indexOf(record.student_id)
                            if(index == -1){
                                this.selectedRowKeys = [record.student_id]
                                this.selectedRecord = [record]
                            }else{
                                this.selectedRowKeys.splice(index,1)
                                this.selectedRecord.splice(index,1)
                            }
                        }
                    }
                }
            },
            searchList(e){
                e?e.preventDefault():''
                this.getList()
            }
        },
    }
</script>

<style lang="scss">
    .mergeStudentModal-table{
       .ant-table-header-column{
           .ant-table-selection{
               display: none;
           }
       }
    }
</style>