<template>
  <a-modal title="学员合并" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="确认合并" width="670px"
    @ok="handleOk" @cancel="handleCancel">
        <a-alert class="copyModal-qrcode-alert" banner type="success">
            <template slot="icon">
                <a-icon type="bell" />
            </template>
            <template slot="message">
                <div style="display:flex">
                    <!-- <div style="width:80px"></div> -->
                    <div>
                        提示：【学员合并操作为不可逆操作，请谨慎使用】学员合并之后，原来的学员信息将删除，同时原学员、剩余课时、上课记录、等会同步到目标学员
                    </div>
                </div>
            </template>
        </a-alert>
        <div class="mergeModal">
            <div class="mergeModal-wrap">
                <div @click="showMergeStudentModal(1)" class="mergeModal-box mergeModal-left">
                    <div v-if="!leftData" class="mergeModal-box-icon">
                        <a-icon style="font-size:36px" type="plus" />
                    </div>
                    <div v-else>
                        <a-form>
                          <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="学员名称"><div style="text-align: left;">{{leftData.student_name}}</div></a-form-item>
                          <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="手机号码"><div style="text-align: left;">{{leftData.cellphone}}</div></a-form-item>
                          <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="剩余课时"><div style="text-align: left;">{{leftData.left_times}}</div></a-form-item>
                          <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="签约校区"><div style="text-align: left;width: 145px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{leftData.studio_id?leftData.studio_id:'--'}}</div></a-form-item>
                          <!-- <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="招生来源"><div style="text-align: left;width: 145px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{leftData.student_channel_id?leftData.student_channel_id:'--'}}</div></a-form-item> -->
                        </a-form>
                    </div>
                </div>
                <div>原学员</div>
            </div>
            <div class="mergeModal-icon">
                <div>合并</div>
                <img src="@/assets/arrow.png" alt="">
            </div>
            <div class="mergeModal-wrap">
                <div @click="showMergeStudentModal(2)" class="mergeModal-box mergeModal-right">
                    <div v-if="!rightData" class="mergeModal-box-icon">
                        <a-icon style="font-size:36px" type="plus" />
                    </div>
                    <div v-else>
                        <a-form>
                          <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="学员名称"><div style="text-align: left;">{{rightData.student_name}}</div></a-form-item>
                          <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="手机号码"><div style="text-align: left;">{{rightData.cellphone}}</div></a-form-item>
                          <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="剩余课时"><div style="text-align: left;">{{rightData.left_times}}</div></a-form-item>
                          <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="签约校区"><div style="text-align: left;width: 145px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{rightData.studio_id?rightData.studio_id:'--'}}</div></a-form-item>
                          <!-- <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="招生来源"><div style="text-align: left;width: 145px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{rightData.student_channel_id?rightData.student_channel_id:'--'}}</div></a-form-item> -->
                        </a-form>
                    </div>
                </div>
                <div>目标学员</div>
            </div>
        </div>
        <mergeStudentModal v-if="isMergeStudentModal" :isTab="isTab" />
  </a-modal>
</template>

<script>
    import mergeStudentModal from './mergeStudentModal'
    export default {
        name:'mergeModal',
        inject: ['parent'],
        data() {
            return {
                visible: false,
                confirmLoading: false,
                isMergeStudentModal:false,
                leftData:'',
                rightData:'',
                isTab:0,
                labelCol: { span: 8 },
                wrapperCol: { span: 16 },
            }
        },
        provide() {
            return {
                parent: this
            }
        },
        components: {
            mergeStudentModal,
        },
        created () {
            this.visible = true
        },
        methods: {
            async handleOk() {
                this.confirmLoading = true
                let that = this
                this.$confirm({
                    title: `您确定合并？`,
                    okText: '确定',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                    return new Promise(async (resolve, reject) => {
                        await that.$store.dispatch('studentsMergeAction', {data:{from_student_id:that.leftData.student_id,to_student_id:that.rightData.student_id}})
                        .then(res=>{
                            that.$message.success('操作成功！！')
                            that.parent.hideMergeModal(1)
                            resolve(res)
                        })
                        .catch(err=>{
                            that.confirmLoading = false
                            resolve(err)
                        })
                    }).catch(error => console.log(error))
                    }
                })


            },
            handleCancel() {
                this.parent.hideMergeModal()
            },
            showMergeStudentModal(val){
                this.isTab = val
                this.isMergeStudentModal = true
            },
            hideMergeStudentModal(obj,num){
                if(obj){
                    num == 1?this.leftData = obj:this.rightData = obj
                }
                this.isMergeStudentModal = false
            }
        },
    }
</script>

<style lang="scss" scoped>
    .mergeModal{
        display: flex;
        align-items: center;
        margin-top: 15px;
        &-wrap{
            text-align: center;
        }
        &-box{
            width: 250px;
            height: 250px;
            background: #F9F9FB;
            cursor: pointer;
            margin-bottom: 15px;
            &-icon{
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
        &-box:hover{
            background: #C6CCD6;
            color: #00cca2;
        }
        &-left{
            border: 1px dashed #DEE2EC;
            padding-top: 25px;
        }
        &-right{
            border: 1px solid #DEE2EC;
            padding-top: 25px;
        }
        &-icon{
            margin: 0 30px;
            div{
                text-align: center;
            }
            img{
                width: 64px;
                height: 32px;
            }
        }
    }
</style>
<style lang="scss">
    .copyModal-qrcode-alert{
        .ant-alert-icon{
            top: 8.5px;
        }
    }
</style>
