<template>
  <div>
    <a-breadcrumb class="breadcrumb">
      <a-breadcrumb-item>首页</a-breadcrumb-item>
      <a-breadcrumb-item>学员中心</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="main-body students">
      <a-tabs :animated="false" :activeKey="acitveKey" @change="changeTab">
        <a-tab-pane tab="学员中心" key="1">
          <zcxy v-if="acitveKey === '1'"/>
        </a-tab-pane>
        <!-- <a-tab-pane tab="学员筛选" key="2" forceRender>
          <xysx  v-if="acitveKey === '2'"/>
        </a-tab-pane> -->
      </a-tabs>
    </div>
  </div>
</template>

<script>
import zcxy from './zcxy/index'
import xysx from './xysx/index'

export default {
  name: 'Students',
  components: {
    zcxy,
    xysx
  },
  data() {
    return {
      acitveKey: '1'
    }
  },

  created() {
  },

  methods: {
    changeTab(key) {
      this.acitveKey = key
    }
  }
}
</script>
