<template>
  <div class="xysx">
    <div class="table-tools">
      <div class="filter">
        <div class="filter-list">
          <div class="filter__label"><span style="letter-spacing: 2em;">性</span>别：</div>
          <ul class="filter__value">
            <li v-for="obj of genders" :key="obj.id">
              <a :class="{'active': searchParams.search['gender'] === obj.id}" @click="changeFilter('gender', obj.id)">{{ obj.name }}</a>
            </li>
          </ul>
        </div>
        <div class="filter-list">
          <div class="filter__label">课程收费：</div>
          <ul class="filter__value">
            <li v-for="obj of userMembercards" :key="obj.id">
              <a :class="{'active': searchParams.search['user_membercards'] === obj.id}" @click="changeFilter('user_membercards', obj.id)">{{ obj.name }}</a>
            </li>
          </ul>
        </div>
        <div class="filter-list">
          <div class="filter__label">购买次数：</div>
          <ul class="filter__value">
            <li v-for="obj of userCostTimes" :key="obj.id">
              <a :class="{'active': searchParams.search['user_cost_times'] === obj.id}" @click="changeFilter('user_cost_times', obj.id)">{{ obj.name }}</a>
            </li>
          </ul>
        </div>
        <div class="filter-list">
          <div class="filter__label">最近消费：</div>
          <ul class="filter__value">
            <li v-for="obj of lastCostAts" :key="obj.id">
              <a :class="{'active': searchParams.search['last_cost_at'] === obj.id}" @click="changeFilter('last_cost_at', obj.id)">{{ obj.name }}</a>
            </li>
          </ul>
        </div>
        <div class="filter-list">
          <div class="filter__label">学员生日：</div>
          <ul class="filter__value">
            <li v-for="obj of births" :key="obj.id">
              <a :class="{'active': searchParams.search['birth'] === obj.id}" @click="changeFilter('birth', obj.id)">{{ obj.name }}</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="fitler-buttons">
        <a-form layout='inline'>
          <a-form-item>
            <a-button type="primary" @click="searchList">搜索</a-button>
          </a-form-item>
          <a-form-item>
            <a-button @click="resetFilter">重置</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="table">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-table size="small" :pagination="false" :bordered='false' rowKey="student_id"
        :columns="columns" :dataSource="list" :scroll="{ x: 1600 }">
        <template slot="index" slot-scope="text, record , index">
          <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
        </template>
        <template slot="studentName" slot-scope="text, record">
          <LUser @eventSon="toUpdataL" :data="record"/>
        </template>
        <template slot="action" slot-scope="text,record">
          <a href="javascript:;" @click="showEditModal(record)">编辑</a>
          <a-divider type="vertical" />
          <a href="javascript:;" @click="toProfile(record)">查看详情</a>
        </template>
      </a-table>
    </div>
    <div class="page">
        <a-pagination
          :pageSizeOptions="pageSizeOptions"
          :total="pageParams.totalCount"
          showSizeChanger
          :pageSize="pageParams.perPage"
          v-model="current"
          @change="changePage"
          @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
        >
            <template slot='buildOptionText' slot-scope='props'>
              <span v-if="props.value!=='100'">{{props.value}}条/页</span>
              <span v-if="props.value==='100'">100条/页</span>
            </template>
          </a-pagination>
      </div>
    <editModal v-if="visible" :item="modalData"/>
    <profileModal v-if="profileVisible" :studentId="studentId"/>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index', fixed: 'left'},
  { title: '学员名称', width: '200px', dataIndex: 'student_name', key: 'student_name', fixed: 'left', scopedSlots: { customRender: 'studentName' } },
  { title: '手机号', width: '150px', dataIndex: 'cellphone', key: 'cellphone'},
  { title: '签约校区', dataIndex: 'studio_id', key: '1' },
  { title: '课程顾问', dataIndex: 'cc_id', key: '2' },
  { title: '课程科目', dataIndex: 'student_classes', key: '3' },
  { title: '剩余课时', dataIndex: 'left_times', key: '4' },
  { title: '赠送课时', dataIndex: 'left_gift_times', key: '5' },
  { title: '报班数', dataIndex: 'student_class_member', key: '6' },
  { title: '学员卡数', dataIndex: 'student_cost_times', key: '7' },
  { title: '上课次数', dataIndex: 'student_classes', key: '8' },
  { title: '请假次数', dataIndex: 'student_cancel_classes', key: '9' },
  { title: '消费金额', dataIndex: 'student_costs', key: '10' },
  { title: '来源渠道', dataIndex: 'student_channel_id', key: '11' },
  { title: '状态', dataIndex: 'student_course_status', key: '12' },
  { title: '操作', key: 'operation', fixed: 'right', width: 118, scopedSlots: { customRender: 'action' }}
]

const genders = [{ id: '', name: '不限' }, { id: 1, name: '男' }, { id: 2, name: '女' }, { id: 0, name: '未知' }]
const userMembercards = [
  { id: '', name: '不限' },
  { id: 1, name: '1+' }, { id: 2, name: '2+' }, { id: 3, name: '3+' }, { id: 4, name: '4+' },
  { id: 5, name: '5+' }, { id: 6, name: '6+' }, { id: 7, name: '7+' }, { id: 8, name: '8+' },
  { id: 9, name: '9+' }, { id: 10, name: '10+' }
]
const userCostTimes = [
  { id: '', name: '不限' },
  { id: 1, name: '1+' }, { id: 2, name: '2+' }, { id: 3, name: '3+' }, { id: 4, name: '4+' },
  { id: 5, name: '5+' }, { id: 10, name: '10+' }, { id: 15, name: '15+' }, { id: 20, name: '20+' },
  { id: 30, name: '30+' }, { id: 50, name: '50+' }
]
const lastCostAts = [
  { id: '', name: '不限' },
  { id: 7, name: '7天' }, { id: 14, name: '14天' }, { id: 30, name: '30天' }, { id: 60, name: '60天' },
  { id: 90, name: '90天' }
]
const births = [
  { id: '', name: '不限' },
  { id: 1, name: '1月' }, { id: 2, name: '2月' }, { id: 3, name: '3月' }, { id: 4, name: '4月' },
  { id: 5, name: '5月' }, { id: 6, name: '6月' }, { id: 7, name: '7月' }, { id: 8, name: '8月' },
  { id: 9, name: '9月' }, { id: 10, name: '10月' }, { id: 11, name: '11月' }, { id: 12, name: '12月' }
]

import tableMixins from '@/common/mixins/table'
import editModal from '../zcxy/editModal'
import profileModal from '@/views/modal/profile'

export default {
  name: 'bjgl',
  mixins: [ tableMixins ],
  components: {
    editModal,
    profileModal
  },

  data() {
    return {
      loading: false,
      columns,

      genders,
      userMembercards,
      userCostTimes,
      lastCostAts,
      births,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      profileVisible: false,
      studentId: ''
    }
  },

  created() {
    this.resetFilter()
  },

  methods: {
    async getList() {
      this.loading = true
      let res = await this.$store.dispatch('studentsStudentsFilterAction', { data: this.searchParams })
      this.list = res.items
      this.pageParams = res._meta
      this.loading = false
    },

    resetFilter() {
      this.searchParams.search = {
        gender: '',
        user_membercards: '',
        user_cost_times: '',
        last_cost_at: '',
        birth: '',
      }
      this.getList()
    },
    changeFilter(key, val) {
      this.searchParams.search[key] = val
      this.getList()
    },
    toUpdataL(){
      this.getList()
    },
    toProfile(item) {
      // this.$router.push(`/work/profile/${item.student_id}`)
      this.studentId = item.student_id
      this.showProfileModal()
    },

    showProfileModal() {
      this.profileVisible = true
    },
    hideProfileModal() {
      this.profileVisible = false
    },
  }
}
</script>
