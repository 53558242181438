<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="1200px"
    @ok="handleOk" @cancel="handleCancel">

    <div class="xyzl">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-form :form="form" layout="horizontal" style="padding: 0 40px;display:flex;justify-content:space-around">
        <div style="position:relative">
          <a-form-item class="xyzl-img"  :label-col="{ span: 10 }" :wrapper-col="{ span: 8 }">
            <div @click="showImagesModal('app_avatar')">
              <LImg :src="imageUrl" style="width: 132px; height: 132px;"/>
            </div>
          </a-form-item>
          <a-form-item label="学员姓名" :label-col="{ span: 10 }" :wrapper-col="{ span: 8 }">
            <a-input style="width:200px" v-decorator="['student_name', {rules: [{required: true,message:'学员名称不能为空！'}]}]"/>
          </a-form-item>
          <a-form-item label="学员性别" :label-col="{ span: 10 }" :wrapper-col="{ span: 8 }">
            <a-radio-group v-decorator="['student_gender']">
              <a-radio :value="2">
                女
              </a-radio>
              <a-radio :value="1">
                男
              </a-radio>
              <a-radio :value="0">
                未知
              </a-radio>
            </a-radio-group>
          </a-form-item>
          <a-form-item label="学籍号" :label-col="{ span: 10 }" :wrapper-col="{ span: 8 }">
            <a-input style="width:200px"  v-decorator="['student_number']"/>
          </a-form-item>
          <a-form-item label="学员手机" :label-col="{ span: 10 }" :wrapper-col="{ span: 8 }">
            <a-input style="width:200px" v-decorator="['student_cellphone',{rules: [{required: true,message:'学员手机号不能为空！'}]}]" />
          </a-form-item>
          <a-form-item label="学员状态" :label-col="{ span: 10 }" :wrapper-col="{ span: 8 }">
            <a-select style="width:400px" allowClear v-decorator="['student_class_status']" placeholder="请选择学员状态">
              <a-select-option v-for="(d, index) of studentStatus" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="出生日期" :label-col="{ span: 10 }" :wrapper-col="{ span: 8 }">
            <a-date-picker style="width:400px" v-decorator="['student_birthday']" format="YYYY-MM-DD"/>
          </a-form-item>
          <a-form-item label="就读学校" :label-col="{ span: 10 }" :wrapper-col="{ span: 8 }">
            <a-input style="width:400px" v-decorator="['school_name']" />
          </a-form-item>
          <a-form-item :label-col="{ span: 10 }" :wrapper-col="{ span: 8 }">
            <template slot="label">
              <span>
                <span style="margin-right:5px">年级</span>
                <a-tooltip>
                  <template slot="title">
                    默认每年的09月01日，学员的年级会自动升级
                  </template>
                  <a-icon type="question-circle" />
                </a-tooltip>
              </span>
            </template>
            <a-select style="width:400px" allowClear v-decorator="['school_year_grade']" placeholder="请选择年级" showSearch :filterOption="filterOption">
                <a-select-option v-for="(d, index) of grades" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
            </a-select>
          </a-form-item>
        </div>
        <div>
          <a-form-item label="入学年份" :label-col="{ span: 10 }" :wrapper-col="{ span: 8 }">
            <a-input style="width:400px" v-decorator="['school_grade']" />
          </a-form-item>
          <a-form-item label="证件号码" :label-col="{ span: 10 }" :wrapper-col="{ span: 8 }">
            <a-input style="width:400px" v-decorator="['student_identity_no']" />
          </a-form-item>
          <!-- <a-form-item label="课程顾问" :label-col="{ span: 10 }" :wrapper-col="{ span: 8 }">
            <a-input style="width:400px" v-decorator="['student_identity_no']" />
          </a-form-item> -->
          <a-form-item label="招生来源" :label-col="{ span: 10 }" :wrapper-col="{ span: 8 }">
            <a-cascader
              :value='channelsId'
              :options="channels"
              :show-search="{ filterOption }"
              placeholder="请选择"
              @change="onChangeCascader">
                <template slot="displayRender" slot-scope="{labels}">
                    <span>{{labels[1]}}</span>
                </template>
              </a-cascader>
          </a-form-item>
          <a-form-item label="体重(Kg)" :label-col="{ span: 10 }" :wrapper-col="{ span: 8 }">
            <a-input-number id="inputNumber" style="width:400px" v-decorator="['student_weight']" />
          </a-form-item>
          <a-form-item label="身高(cm)" :label-col="{ span: 10 }" :wrapper-col="{ span: 8 }">
            <a-input-number id="inputNumber" style="width:400px" v-decorator="['student_height']" />
          </a-form-item>
          <a-form-item label="家庭住址" :label-col="{ span: 10 }" :wrapper-col="{ span: 8 }">
            <a-input style="width:400px" v-decorator="['student_address']" />
          </a-form-item>
          <a-form-item label="健康状况" :label-col="{ span: 10 }" :wrapper-col="{ span: 8 }">
            <a-input style="width:400px" v-decorator="['student_health_status']" />
          </a-form-item>
          <a-form-item label="学生备注" :label-col="{ span: 10 }" :wrapper-col="{ span: 8 }">
            <a-textarea style="width:400px" v-decorator="['student_remark']" />
          </a-form-item>
        </div>
      </a-form>
    </div>




    <ImageModal v-if="imagesVisible"/>
  </a-modal>
</template>

<style lang="scss">
.xyzl .ant-form-item {
  margin-bottom: 0;
}
.xyzl .ant-form-item label {
  display: inline;
}
.xyzl .ant-col-10{
  width: 22%;
}
.xyzl .ant-form-item-control{
  width: 400px;
}
.xyzl .xyzl-img{
  position: absolute;
  width: 132px;
  height: 132px;
  right: 10px;
  z-index: 10;
  overflow: hidden;
  img{
    width: 132px;
    height: 132px;
  }
}
</style>

<script>
import url from '@/utils/URL'
import ImageModal from '@/views/modal/images'
import C_ITEMS from '@/utils/items'
import moment from 'moment'
export default {
  inject: ['parent'],
  props: {
    item: Object
  },
  provide() {
    return {
      parent: this
    }
  },
  components: {
    ImageModal,
  },

  data() {
    return {
      loading:false,
      labelCol: { span: 5 },
      wrapperCol: { span: 15 },
      ModalTitle: '编辑',
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      imageUrl: '',
      imageRoot: url.imageRoot,
      uploadParams: {},
      imagesVisible: false,
      imageKey: '',
      channelsId:[],
      sexs: C_ITEMS.sexs,
      grades:C_ITEMS.grade,
      studentStatus: C_ITEMS.studentStatus,
      studentContactTypes: C_ITEMS.studentContactTypes,
      channels: [],
      consultants: [],
    }
  },

  async created() {
    this.visible = true
    await this.getDetail()
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    async getDetail() {
      this.loading = true
      let { student_id } = this.item
      let res = await this.$store.dispatch('studentsDetailAction', {data: {student_id}})
      console.log(res)
      await this.$nextTick()
      let d = res.data
      this.form.setFieldsValue({
        student_name: d.student_name,
        student_gender: d.student_gender,
        student_birthday: d.student_birthday && moment(d.student_birthday, 'YYYY-MM-DD'),
        student_height: d.student_height,
        student_weight: d.student_weight,
        student_cellphone: d.student_cellphone,
        student_health_status: d.student_health_status,
        student_identity_no: d.student_identity_no,
        student_number: d.student_number,
        school_name: d.school_name,
        school_year_grade: d.school_year_grade?d.school_year_grade:undefined,
        school_grade: d.school_grade,
        student_address: d.student_address,
        student_remark: d.student_remark,
        student_class_status: d.student_class_status,
      })
      this.getSourceChannel(d.student_channel_id)
      this.imageUrl = d.student_avatar
      this.loading = false;
    },
    async getSourceChannel(id) {
      var that = this;
      let res = await this.$store.dispatch('searchSourceChannelCascaderAction', {})
      this.channels = res.data[0].children
      res.data[0].children.forEach((item)=>{
          item.children.forEach((it)=>{
            if(it.value == id){
              that.channelsId = [item.value,it.value]
            }
          })
        })
    },
    async handleOk() {
      try {
        let data = await this.form.validateFields()
        this.confirmLoading=true;
        data.student_avatar = this.imageUrl
        data.student_id = this.item.student_id
        data.student_channel_id = this.channelsId[1]? this.channelsId[1]:''
        data.student_birthday = data.student_birthday ? moment(data.student_birthday).format('YYYY-MM-DD'):undefined
        await this.$store.dispatch('studentsUpdateAction', {data})
          .then(res=>{
            this.$message.success('操作成功！')
            this.form.resetFields()
            this.parent.hideUpdateModal(1)
          })
          .catch(err=>{
            this.confirmLoading=false;
          })
      } catch {
      }
    },
    handleCancel() {
      this.parent.hideUpdateModal(0)
    },
    
    closeImg(e) {
      e.stopPropagation()
      this.imageUrl = ''
    },
    showImagesModal(imageKey) {
      this.imageKey = imageKey
      this.imagesVisible = true
    },
    hideImagesModal(image) {
      if (image) {
        this.imageUrl = image.path
      }
      this.imagesVisible = false
    },
    handleSearch(val){
      this.getSourceChannel(val)
    },
    onChangeCascader(value, selectedOptions) {
      this.channelsId = value;
    },
  }
}
</script>